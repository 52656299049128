import {
  BannerStateDispatchType,
  GetAllBanners,
} from "@paperdateco/admin/store/banner/BannerActions";
import Table, {
  Option,
} from "@paperdateco/shared-frontend/components/table/Table";
import { useDispatch, useSelector } from "react-redux";

import AddNewButton from "@paperdateco/shared-frontend/components/common/form/button/AddNewButton";
import BannerColumns from "@paperdateco/shared-frontend/components/design/table/BannerColumns";
import BannerDto from "@paperdateco/common/dto/banner/BannerDto";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import Column from "@paperdateco/shared-frontend/components/table/Column";
import DashboardContentLayout from "@paperdateco/shared-frontend/components/layout/DashboardContentLayout";
import routes from "@paperdateco/admin/routes/routes";
import { selectBanners } from "@paperdateco/admin/store/banner/BannerSelector";
import useClickListener from "@paperdateco/shared-frontend/components/common/hooks/useClickListener";
import { useEffect } from "react";

const columns: Column<BannerDto>[] = [
  BannerColumns.id,
  BannerColumns.name,
  BannerColumns.banner,
  BannerColumns.text,
  BannerColumns.button,
];

const allColumns = Object.values(BannerColumns);

const BannerTableOptions: Option<BannerDto> = {
  rowStyle: (row: BannerDto) => ({
    backgroundColor: row.visible ? Colors.WHITE : Colors.LIGHT_RED,
  }),
};

export default function BannersListPage() {
  const banners = useSelector(selectBanners);

  const dispatch = useDispatch<BannerStateDispatchType>();

  useEffect(() => {
    dispatch(GetAllBanners());
  }, [dispatch]);

  const goToEditBanner = useClickListener((banner?: BannerDto) => {
    if (banner?.id) {
      return routes.BANNERS_EDIT(banner.id);
    }
  });

  return (
    <DashboardContentLayout>
      <AddNewButton
        position="right"
        link={routes.BANNERS_ADD}
        name="BANNER"
        marginBottom={2}
      />
      <Table
        title="Banners List"
        data={banners}
        columns={columns}
        allColumns={allColumns}
        onRowClick={goToEditBanner}
        options={BannerTableOptions}
      />
    </DashboardContentLayout>
  );
}
