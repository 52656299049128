import { COUPON_GET_ITEMS_NAME } from "./CouponTypes";
import CouponApi from "@paperdateco/common/api/CouponApi";
import CouponDto from "@paperdateco/common/dto/coupon/CouponDto";
import GetItemsAction from "@paperdateco/shared-frontend/store/getItems/GetItemsAction";
import GetItemsActionTypes from "@paperdateco/shared-frontend/store/getItems/GetItemsTypes";
import { RootState } from "..";
import { ThunkDispatch } from "redux-thunk";

export const GetAllCoupons = GetItemsAction(
  COUPON_GET_ITEMS_NAME,
  CouponApi.getAllCoupons,
  (state: RootState) => state.coupons
);

export type CouponStateDispatchType = ThunkDispatch<
  RootState,
  unknown,
  GetItemsActionTypes<CouponDto>
>;
