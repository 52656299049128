import {
  LibraryAnimationStateDispatchType,
  getAllLibraryAnimations,
} from "@paperdateco/admin/store/libraryAnimation/LibraryAnimationAction";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import DashboardContentLayout from "@paperdateco/shared-frontend/components/layout/DashboardContentLayout";
import LibraryAnimationListTable from "@paperdateco/shared-frontend/components/table/custom/LibraryAnimationListTable";
import { selectLibraryAnimations } from "@paperdateco/admin/store/libraryAnimation/LibraryAnimationSelector";
import useEditLibraryAnimation from "@paperdateco/shared-frontend/pages/libraryAnimation/useEditLibraryAnimation";

export default function LibraryAnimationPage() {
  const animations = useSelector(selectLibraryAnimations);
  const dispatch = useDispatch<LibraryAnimationStateDispatchType>();

  useEffect(() => {
    dispatch(getAllLibraryAnimations());
  }, [dispatch]);

  const onUpdateAnimation = useCallback(() => {
    dispatch(getAllLibraryAnimations(true));
  }, [dispatch]);

  const onEditAnimation = useEditLibraryAnimation(true, onUpdateAnimation);

  return (
    <DashboardContentLayout>
      <LibraryAnimationListTable
        title="Library Animation"
        items={animations}
        onEditItem={onEditAnimation}
      />
    </DashboardContentLayout>
  );
}
