import {
  DesignStateDispatchType,
  getAllDesigns,
} from "@paperdateco/admin/store/design/DesignActions";
import { useDispatch, useSelector } from "react-redux";

import Column from "@paperdateco/shared-frontend/components/table/Column";
import DesignColumns from "@paperdateco/shared-frontend/components/design/table/DesignColumns";
import DesignDto from "@paperdateco/common/dto/design/DesignDto";
import Table from "@paperdateco/shared-frontend/components/table/Table";
import routes from "@paperdateco/admin/routes/routes";
import { selectDesigns } from "@paperdateco/admin/store/design/DesignSelector";
import useClickListener from "@paperdateco/shared-frontend/components/common/hooks/useClickListener";
import { useEffect } from "react";

const columns: Column<DesignDto>[] = [
  DesignColumns.id,
  DesignColumns.name,
  DesignColumns.image,
  DesignColumns.layers,
];

const allColumns = Object.values(DesignColumns);

export default function DesignListTable() {
  const designs = useSelector(selectDesigns);

  const dispatch = useDispatch<DesignStateDispatchType>();

  useEffect(() => {
    dispatch(getAllDesigns());
  }, [dispatch]);

  const goToEditDesign = useClickListener((design?: DesignDto) => {
    if (design?.id) {
      return routes.DESIGN_EDIT(design.id);
    }
  });

  return (
    <Table
      title="Designs list"
      data={designs}
      columns={columns}
      allColumns={allColumns}
      onRowClick={goToEditDesign}
    />
  );
}
