import { Navigate, useLocation } from "react-router-dom";
import { PropsWithChildren, useContext } from "react";

import { AuthContext } from "@paperdateco/shared-frontend/components/auth/AuthProvider";
import UserRole from "@paperdateco/common/dto/auth/UserRole";

interface AdminRouteProps {
  redirectTo: string;
}

export default function AdminRoute({
  redirectTo,
  children,
}: PropsWithChildren<AdminRouteProps>) {
  const { user } = useContext(AuthContext);
  const location = useLocation();

  if (!(user && user.roles.includes(UserRole.ADMIN))) {
    return (
      <Navigate
        to={redirectTo}
        state={{
          from: location.pathname,
          accessDenied: user && !user.roles.includes(UserRole.ADMIN),
        }}
      />
    );
  }

  return <>{children}</>;
}
