import GetItemsAction from "@paperdateco/shared-frontend/store/getItems/GetItemsAction";
import GetItemsActionTypes from "@paperdateco/shared-frontend/store/getItems/GetItemsTypes";
import { RootState } from "..";
import { ThunkDispatch } from "redux-thunk";
import { USER_GET_ITEMS_NAME } from "./UserTypes";
import UserApi from "@paperdateco/common/api/UserApi";
import UserDto from "@paperdateco/common/dto/user/UserDto";

export const getUsers = GetItemsAction(
  USER_GET_ITEMS_NAME,
  UserApi.getAll,
  (state: RootState) => state.user
);

export type UserStateDispatchType = ThunkDispatch<
  RootState,
  unknown,
  GetItemsActionTypes<UserDto>
>;
