import GetItemsReducer from "../getItems/GetItemsReducer";
import { LIBRARY_ANIMATIONS } from "./LibraryAnimationTypes";
import LibraryAnimationDto from "@paperdateco/common/dto/design/library/animation/LibraryAnimationDto";

const LibraryAnimationReducer = GetItemsReducer<LibraryAnimationDto[]>(
  LIBRARY_ANIMATIONS,
  []
);

export default LibraryAnimationReducer;
