import ReactPlayer from "react-player";

interface ForegroundSelectProps {
  src?: string;
  alt?: string;
  type: string;
  customWidth?: string;
  customHeight?: string;
}

export default function ForegroundSelect({
  src,
  alt,
  type,
  customWidth,
  customHeight,
}: ForegroundSelectProps) {
  if (!src) {
    return null;
  }
  if (!type) {
    type = "image";
  }

  return (
    <>
      {type === "image" ? (
        <img width={customWidth} height={customHeight} src={src} alt={alt} />
      ) : (
        <ReactPlayer
          url={[{ src: src, type: "video/mp4" }]}
          playing
          playsinline
          loop
          muted
          width="100%"
          height="100%"
        />
      )}
    </>
  );
}
