import LibraryAnimationDto from "@paperdateco/common/dto/design/library/animation/LibraryAnimationDto";
import LibraryAnimationFormModal from "./LibraryAnimationFormModal";
import { ModalContext } from "@paperdateco/shared-frontend/components/providers/ModalProvider";
import { useContext } from "react";

export default function useEditLibraryAnimation(
  isAdmin: boolean,
  onSubmit?: () => void
) {
  const { open, close } = useContext(ModalContext);

  const onEditLibraryAnimation = (libraryAnimation: LibraryAnimationDto) => {
    const onSubmitForm = () => {
      onSubmit?.();
      close();
    };
    open(
      <LibraryAnimationFormModal
        isAdmin={isAdmin}
        libraryAnimation={libraryAnimation}
        onSubmit={onSubmitForm}
      />
    );
  };

  return onEditLibraryAnimation;
}
