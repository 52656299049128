import {
  BannerStateDispatchType,
  GetAllBanners,
} from "@paperdateco/admin/store/banner/BannerActions";

import routes from "@paperdateco/admin/routes/routes";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

export default function useBannerFormState() {
  const dispatch = useDispatch<BannerStateDispatchType>();
  const navigate = useNavigate();

  const onSubmit = useCallback(() => {
    dispatch(GetAllBanners(true));
    navigate(routes.BANNERS);
  }, [dispatch, navigate]);

  return { onSubmit };
}
