import { useEffect, useState } from "react";

import BannerApi from "@paperdateco/common/api/bannerApi";
import BannerDto from "@paperdateco/common/dto/banner/BannerDto";
import BannerForm from "./BannerForm";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import useBannerFormState from "./useBannerFormState";
import { useParams } from "react-router-dom";

export default function BannerEditPage() {
  const { bannerId } = useParams<{ bannerId: string }>();
  const [banner, setBanner] = useState<BannerDto>();

  const { onSubmit } = useBannerFormState();

  useEffect(() => {
    if (!bannerId) {
      return;
    }
    BannerApi.getBanner(bannerId)
      .then(setBanner)
      .catch(NotificationUtils.showGenericError);
  }, [bannerId]);

  return <BannerForm banner={banner} onSubmit={onSubmit} />;
}
