import {
  UserStateDispatchType,
  getUsers,
} from "@paperdateco/admin/store/user/UserActions";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import UserApi from "@paperdateco/common/api/UserApi";
import UserDto from "@paperdateco/common/dto/user/UserDto";
import UserForm from "./UserForm";
import routes from "@paperdateco/admin/routes/routes";
import { useDispatch } from "react-redux";

export default function UserEditPage() {
  const { userId } = useParams<{ userId: string }>();
  const [user, setUser] = useState<UserDto>();
  const dispatch = useDispatch<UserStateDispatchType>();
  const navigate = useNavigate();

  const onUpdateUser = useCallback(() => {
    dispatch(getUsers(true));
    navigate(routes.USERS);
  }, [dispatch, navigate]);

  useEffect(() => {
    if (!userId) {
      return;
    }
    UserApi.getOne(userId)
      .then(setUser)
      .catch(NotificationUtils.showGenericError);
  }, [userId]);

  return <UserForm user={user} onSubmit={onUpdateUser} />;
}
