import { DESIGN_GET_ITEMS_NAME } from "./DesignTypes";
import DesignApi from "@paperdateco/common/api/DesignApi";
import DesignDto from "@paperdateco/common/dto/design/DesignDto";
import GetItemsAction from "@paperdateco/shared-frontend/store/getItems/GetItemsAction";
import GetItemsActionTypes from "@paperdateco/shared-frontend/store/getItems/GetItemsTypes";
import { RootState } from "..";
import { ThunkDispatch } from "redux-thunk";

export const getAllDesigns = GetItemsAction(
  DESIGN_GET_ITEMS_NAME,
  DesignApi.getAll,
  (state: RootState) => state.design
);

export type DesignStateDispatchType = ThunkDispatch<
  RootState,
  unknown,
  GetItemsActionTypes<DesignDto>
>;
