import Column from "@paperdateco/shared-frontend/components/table/Column";
import FeedColumns from "@paperdateco/shared-frontend/components/design/table/InstagramFeedColumns";
import FeedItemDto from "@paperdateco/common/dto/instagramFeed/FeedItemDto";
import Table from "@paperdateco/shared-frontend/components/table/Table";
import useClickListener from "@paperdateco/shared-frontend/components/common/hooks/useClickListener";

const feedColumns: Column<FeedItemDto>[] = [
  FeedColumns.id,
  FeedColumns.feed,
  FeedColumns.description,
  FeedColumns.date,
];

const allColumns = Object.values(FeedColumns);

interface FeedTableProps {
  feeds: FeedItemDto[];
}

export default function FeedTable({ feeds }: FeedTableProps) {
  const onRowClick = useClickListener((item?: FeedItemDto) => {
    return item?.link;
  });
  return (
    <Table
      title="Instagram Feeds list"
      options={{ search: false }}
      data={feeds}
      columns={feedColumns}
      allColumns={allColumns}
      onRowClick={onRowClick}
    />
  );
}
