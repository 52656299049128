import {
  GetAllProducts,
  ProductStateDispatchType,
} from "@paperdateco/admin/store/products/ProductActions";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import AddNewButton from "@paperdateco/shared-frontend/components/common/form/button/AddNewButton";
import DashboardContentLayout from "@paperdateco/shared-frontend/components/layout/DashboardContentLayout";
import ProductDto from "@paperdateco/common/dto/product/ProductDto";
import ProductTable from "../../table/ProductTable";
import routes from "@paperdateco/admin/routes/routes";
import { selectProducts } from "@paperdateco/admin/store/products/ProductSelector";

export default function ProductsListPage() {
  const products = useSelector(selectProducts);

  const dispatch = useDispatch<ProductStateDispatchType>();

  useEffect(() => {
    dispatch(GetAllProducts());
  }, [dispatch]);

  const onEditProduct = useCallback(
    (product: ProductDto) => routes.PRODUCTS_EDIT(product.id),
    []
  );

  return (
    <DashboardContentLayout>
      <AddNewButton
        position="right"
        link={routes.PRODUCTS_ADD}
        name="PRODUCT"
        marginBottom={2}
      />
      <ProductTable products={products} onEditProduct={onEditProduct} />
    </DashboardContentLayout>
  );
}
