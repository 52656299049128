import {
  GetAllOrders,
  OrderStateDispatchType,
} from "@paperdateco/admin/store/orders/OrderActions";
import { useDispatch, useSelector } from "react-redux";

import Column from "@paperdateco/shared-frontend/components/table/Column";
import DashboardContentLayout from "@paperdateco/shared-frontend/components/layout/DashboardContentLayout";
import DesignUtils from "@paperdateco/common/utils/DesignUtils";
import OrderDetailedDto from "@paperdateco/common/dto/order/OrderDetailedDto";
import OrderItemDto from "@paperdateco/common/dto/order/details/OrderItemDto";
import StringUtils from "@paperdateco/common/utils/StringUtils";
import Table from "@paperdateco/shared-frontend/components/table/Table";
import { selectOrders } from "@paperdateco/admin/store/orders/OrderSelector";
import { useEffect } from "react";

export enum OrderFieldNames {
  ID = "id",
  ITEMS = "items",
  USER = "user",
  COUPON = "coupon",
  PRICE = "price",
  STATUS = "status",
  ORDER_TIME = "orderTime",
}

export enum OrderItemFieldNames {
  ID = "id",
  NAME = "name",
  IMAGE = "image",
  PRICE = "price",
}

export const OrderItemsColumns: {
  [key in OrderItemFieldNames]: Column<OrderItemDto>;
} = {
  id: { title: "Product Id", field: "id" },
  name: {
    title: "Name",
    field: "product",
    render: (data) => (
      <a
        href={"https://paperdateco.com/product/" + data.product.slug}
        target="_blank"
        rel="noreferrer"
      >
        {data.product.name}
      </a>
    ),
  },
  image: {
    title: "Image",
    field: "product",
    render: (data) => (
      <img
        width={100}
        src={DesignUtils.getImageFromOrderItem(data)}
        alt={data.product.name}
      />
    ),
  },
  price: {
    title: "Price",
    field: "product",
    render: (data) => (
      <dl>
        <dt>Product = {data.product.price}</dt>
        <dt>
          Text = {DesignUtils.calculateTextPrice(data.customDesign.layers)}
        </dt>
        <dt>
          Envelop = {DesignUtils.calculateEnvelopePrice(data.customDesign)}
        </dt>
        <dt>
          Premium Elements ={" "}
          {DesignUtils.calculatePremiumElementsPrice(
            data.customDesign.layers,
            data.customDesign.components.background
          )}
        </dt>
      </dl>
    ),
  },
};

export const OrderColumns: {
  [key in OrderFieldNames]: Column<OrderDetailedDto>;
} = {
  id: {
    title: "Order Id",
    field: "id",
    render: (order) => StringUtils.shortenObjectId(order.id),
  },
  items: {
    title: "Items",
    field: "items",
    render: (data) => data.items.length,
  },
  user: { title: "User", field: "user", render: (order) => order.user.email },
  coupon: {
    title: "Coupon",
    field: "coupon",
    render: (order) => order.coupon?.name,
  },
  price: {
    title: "Total",
    field: "price",
    render: (order) => (
      <dl>
        <dt>Sub Total = {order.price.subTotal}</dt>
        <dt>Discount = {order.price.discount}</dt>
        <dt>Tax = {order.price.tax}</dt>
        <dt>Total = {order.price.total}</dt>
      </dl>
    ),
  },
  status: { title: "Status", field: "status" },
  orderTime: {
    title: "Date",
    field: "orderTime",
    render: (order) => StringUtils.formatDate(order.orderTime),
  },
};

const orderColumns: Column<OrderDetailedDto>[] = [
  OrderColumns.id,
  OrderColumns.user,
  OrderColumns.orderTime,
  OrderColumns.price,
  OrderColumns.items,
];

const orderItemsColumns: Column<OrderItemDto>[] = [
  OrderItemsColumns.name,
  OrderItemsColumns.image,
  OrderItemsColumns.price,
];

const allColumns = Object.values(OrderColumns);

export default function OrdersPage() {
  const orders = useSelector(selectOrders);

  const dispatch = useDispatch<OrderStateDispatchType>();

  useEffect(() => {
    dispatch(GetAllOrders());
  }, [dispatch]);

  return (
    <DashboardContentLayout>
      <Table
        title="Orders list"
        data={orders}
        columns={orderColumns}
        allColumns={allColumns}
        detailPanel={[
          {
            tooltip: "Show Products",
            render: (data) => {
              return (
                <Table
                  options={{ search: false }}
                  title="Products"
                  data={data.rowData.items}
                  columns={orderItemsColumns}
                />
              );
            },
          },
        ]}
      />
    </DashboardContentLayout>
  );
}
