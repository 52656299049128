import {
  CategoryStateDispatchType,
  GetAllCategories,
} from "@paperdateco/admin/store/category/CategoryActions";
import Table, {
  Option,
} from "@paperdateco/shared-frontend/components/table/Table";
import { useDispatch, useSelector } from "react-redux";

import AddNewButton from "@paperdateco/shared-frontend/components/common/form/button/AddNewButton";
import CategoryColumns from "@paperdateco/shared-frontend/components/design/table/CategoryColumns";
import CategoryDto from "@paperdateco/common/dto/category/CategoryDto";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import Column from "@paperdateco/shared-frontend/components/table/Column";
import DashboardContentLayout from "@paperdateco/shared-frontend/components/layout/DashboardContentLayout";
import routes from "@paperdateco/admin/routes/routes";
import { selectCategories } from "@paperdateco/admin/store/category/CategorySelector";
import useClickListener from "@paperdateco/shared-frontend/components/common/hooks/useClickListener";
import { useEffect } from "react";

const columns: Column<CategoryDto>[] = [
  CategoryColumns.id,
  CategoryColumns.name,
  CategoryColumns.slug,
  CategoryColumns.image,
];

const allColumns = Object.values(CategoryColumns);

const CategoryTableOptions: Option<CategoryDto> = {
  rowStyle: (row: CategoryDto) => ({
    backgroundColor: row.visible ? Colors.WHITE : Colors.LIGHT_RED,
  }),
};

export default function CatogoriesListPage() {
  const categories = useSelector(selectCategories);

  const dispatch = useDispatch<CategoryStateDispatchType>();

  useEffect(() => {
    dispatch(GetAllCategories());
  }, [dispatch]);

  const goToEditCategory = useClickListener((category?: CategoryDto) => {
    if (category?.id) {
      return routes.CATEGORIES_EDIT(category.id);
    }
  });

  return (
    <DashboardContentLayout>
      <AddNewButton
        position="right"
        link={routes.CATEGORIES_ADD}
        name="CATEGORY"
        marginBottom={2}
      />
      <Table
        title="Categories List"
        data={categories}
        columns={columns}
        allColumns={allColumns}
        onRowClick={goToEditCategory}
        options={CategoryTableOptions}
      />
    </DashboardContentLayout>
  );
}
