import BannerReducer from "./banner/BannerReducer";
import CategoryReducer from "./category/CategoryReducer";
import CouponReducer from "./coupons/CouponReducer";
import DesignReducer from "./design/DesignReducer";
import LibraryAnimationReducer from "@paperdateco/shared-frontend/store/libraryAnimation/LibraryAnimationReducer";
import LibraryImageReducer from "./libraryImage/LibraryImageReducer";
import OrderReducer from "./orders/OrderReducer";
import ProductReducer from "./products/ProductReducer";
import UserReducer from "./user/UserReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  user: UserReducer,
  order: OrderReducer,
  design: DesignReducer,
  libraryImages: LibraryImageReducer,
  libraryAnimations: LibraryAnimationReducer,
  coupons: CouponReducer,
  products: ProductReducer,
  categories: CategoryReducer,
  banners: BannerReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
