import Table, {
  Action,
  Option,
} from "@paperdateco/shared-frontend/components/table/Table";
import { useCallback, useMemo } from "react";

import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import Column from "@paperdateco/shared-frontend/components/table/Column";
import CouponColumns from "@paperdateco/shared-frontend/components/design/table/CouponColumns";
import CouponDto from "@paperdateco/common/dto/coupon/CouponDto";
import EditIcon from "@mui/icons-material/Edit";
import HideSourceIcon from "@mui/icons-material/HideSource";

const columns: Column<CouponDto>[] = [
  CouponColumns.name,
  CouponColumns.type,
  CouponColumns.value,
  CouponColumns.perUserLimit,
];

const allColumns = Object.values(CouponColumns);

const CouponTableOptions: Option<CouponDto> = {
  rowStyle: (row) => ({
    backgroundColor: row.enabled ? Colors.WHITE : Colors.LIGHT_RED,
  }),
  actionsColumnIndex: -1,
};

interface CouponTableProps {
  coupons: CouponDto[];
  onEdit?: (coupon: CouponDto) => void;
  onDelete?: (coupon: CouponDto) => void;
}

export default function CouponTable({
  coupons,
  onEdit,
  onDelete,
}: CouponTableProps) {
  const onEditCoupon = useCallback(
    (_: any, item: CouponDto | CouponDto[]) => {
      let coupon = Array.isArray(item) ? item[0] : item;
      onEdit?.(coupon);
    },
    [onEdit]
  );

  const onDisableCoupon = useCallback(
    (_: any, item: CouponDto | CouponDto[]) => {
      let coupon = Array.isArray(item) ? item[0] : item;
      onDelete?.(coupon);
    },
    [onDelete]
  );

  const CouponTableActions = useMemo<Action<CouponDto>[]>(
    () => [
      {
        icon: () => <EditIcon />,
        tooltip: "Edit coupon",
        onClick: onEditCoupon,
      },
      {
        icon: () => <HideSourceIcon />,
        tooltip: "Disable coupon",
        onClick: onDisableCoupon,
      },
    ],
    [onEditCoupon, onDisableCoupon]
  );

  return (
    <Table
      title="Coupon list"
      data={coupons}
      columns={columns}
      allColumns={allColumns}
      actions={CouponTableActions}
      options={CouponTableOptions}
    />
  );
}
