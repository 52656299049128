import { Button, Stack } from "@mui/material";
import { FormEvent, useCallback, useEffect, useState } from "react";

import AdminCategoryUpdateRequestDto from "@paperdateco/common/dto/category/AdminCategoryUpdateRequestDto";
import BackButton from "@paperdateco/shared-frontend/components/common/form/button/BackButton";
import CategoryApi from "@paperdateco/common/api/product/CategoryApi";
import CategoryDto from "@paperdateco/common/dto/category/CategoryDto";
import CustomAutocomplete from "@paperdateco/shared-frontend/components/common/form/CustomAutoComplete";
import CustomMediaPicker from "@paperdateco/shared-frontend/components/common/form/CustomMediaPicker";
import CustomTextField from "@paperdateco/shared-frontend/components/common/form/CustomTextField";
import DashboardContentLayout from "@paperdateco/shared-frontend/components/layout/DashboardContentLayout";
import FormContainer from "@paperdateco/shared-frontend/components/common/form/FormContainer";
import FormRow from "@paperdateco/shared-frontend/components/common/form/FormRow";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import StringUtils from "@paperdateco/common/utils/StringUtils";
import UploadTypes from "@paperdateco/common/dto/common/UploadTypes";
import routes from "@paperdateco/admin/routes/routes";

interface CategoryFormProps {
  categories: CategoryDto[];
  category?: CategoryDto;
  onSubmit?: (category: CategoryDto) => void;
}

export default function CategoryForm({
  categories,
  category,
  onSubmit,
}: CategoryFormProps) {
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [image, setImage] = useState("");
  const [type, setType] = useState(UploadTypes.IMAGE);
  const [parent, setParent] = useState<string>();
  const filledSlug = StringUtils.useWhenEmpty(
    StringUtils.encodeAsUri(name),
    slug
  );

  useEffect(() => {
    setName(category?.name ?? "");
    setSlug(category?.slug ?? "");
    setImage(category?.image ?? "");
    setType(category?.type ?? UploadTypes.IMAGE);
    setParent(category?.parent);
  }, [category]);

  const onUpdate = useCallback(
    (newCategory: CategoryDto) => {
      onSubmit?.(newCategory);
    },
    [onSubmit]
  );

  const onUploadImage = useCallback(async (file: File) => {
    const { url, type } = await CategoryApi.uploadCategoryImage(file);
    setImage(url);
    setType(type);
  }, []);

  const onHideImage = () => {
    if (!category) {
      return;
    }
    CategoryApi.hideCategory(category.id)
      .then(onUpdate)
      .catch(NotificationUtils.showGenericError);
  };

  const onSubmitForm = async (e: FormEvent) => {
    e.preventDefault();

    const request: AdminCategoryUpdateRequestDto = {
      name,
      slug: filledSlug,
      image,
      parent,
      type,
    };

    try {
      const newCategory = category?.id
        ? await CategoryApi.updateCategory(category.id, request)
        : await CategoryApi.addCategory(request);
      onUpdate(newCategory);
    } catch (e) {
      NotificationUtils.showGenericError(e);
    }
  };

  const categoryOptions = categories.map((category) => category.id);
  const categoryLabel = useCallback(
    (id: string) =>
      categories.find((category) => category.id === id)?.name ?? "",
    [categories]
  );

  return (
    <DashboardContentLayout>
      <BackButton link={routes.CATEGORIES} name="Categories" />
      <FormContainer onSubmit={onSubmitForm}>
        <FormRow>
          <CustomTextField value={name} onChange={setName} label="Name" />
        </FormRow>
        <FormRow>
          <CustomTextField
            value={filledSlug}
            onChange={setSlug}
            label="SEO URL Name"
          />
        </FormRow>

        <FormRow display="flex" alignItems="center">
          <CustomMediaPicker
            url={image}
            type={type}
            onUpload={onUploadImage}
            width={250}
          />
        </FormRow>

        <FormRow>
          <CustomAutocomplete
            options={categoryOptions}
            label="Parent Category"
            getOptionLabel={categoryLabel}
            value={parent}
            onChange={setParent}
          />
        </FormRow>
        <FormRow textAlign="center">
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Button variant="contained" type="submit" size="large">
              {category ? "UPDATE" : "ADD"}
            </Button>

            {category?.visible && (
              <Button
                variant="contained"
                type="button"
                size="large"
                onClick={onHideImage}
              >
                HIDE
              </Button>
            )}
          </Stack>
        </FormRow>
      </FormContainer>
    </DashboardContentLayout>
  );
}
