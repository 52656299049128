import AppsIcon from "@mui/icons-material/Apps";
import CategoryIcon from "@mui/icons-material/Category";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import DrawerTabType from "@paperdateco/shared-frontend/components/layout/drawer/DrawerTabType";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import PeopleIcon from "@mui/icons-material/People";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import ResponsiveDrawer from "@paperdateco/shared-frontend/components/layout/ResponsiveDrawer";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import ViewCarouselOutlinedIcon from "@mui/icons-material/ViewCarouselOutlined";
import logo from "@paperdateco/shared-frontend/assets/images/logo/small_logo.png";
import routes from "@paperdateco/admin/routes/routes";

export const PortalTabs: DrawerTabType[] = [
  {
    id: "dashboard",
    icon: DashboardIcon,
    label: "Dashboard",
    route: routes.DASHBOARD,
  },
  {
    id: "banner",
    icon: ViewCarouselOutlinedIcon,
    label: "Banner",
    route: routes.BANNERS,
  },
  {
    id: "category",
    icon: CategoryIcon,
    label: "Category",
    route: routes.CATEGORIES,
  },
  {
    id: "coupons",
    icon: LocalOfferIcon,
    label: "Coupons",
    route: routes.COUPONS,
  },
  {
    id: "designs",
    icon: DesignServicesIcon,
    label: "Designs",
    route: routes.DESIGNS,
  },
  {
    id: "instagram",
    icon: InstagramIcon,
    label: "Instagram",
    route: routes.INSTAGRAM,
  },
  {
    id: "library",
    icon: PhotoLibraryIcon,
    label: "Library",
    route: routes.LIBRARY,
  },
  {
    id: "orders",
    icon: ShoppingBasketIcon,
    label: "Orders",
    route: routes.ORDERS,
  },
  {
    id: "products",
    icon: AppsIcon,
    label: "Products",
    route: routes.PRODUCTS,
  },
  {
    id: "users",
    icon: PeopleIcon,
    label: "Users",
    route: routes.USERS,
  },
  {
    id: "logout",
    icon: ExitToAppIcon,
    label: "Logout",
    route: routes.LOGOUT,
  },
];

export default function PortalPage() {
  return (
    <ResponsiveDrawer
      tabs={PortalTabs}
      defaultRoute={routes.DASHBOARD}
      logo={logo}
    >
      Paper Date Co. Admin Panel
    </ResponsiveDrawer>
  );
}
