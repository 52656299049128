import GetItemsReducer from "@paperdateco/shared-frontend/store/getItems/GetItemsReducer";
import { PRODUCT_GET_ITEMS_NAME } from "./ProductTypes";
import ProductDto from "@paperdateco/common/dto/product/ProductDto";

const ProductReducer = GetItemsReducer<ProductDto[]>(
  PRODUCT_GET_ITEMS_NAME,
  []
);

export default ProductReducer;
