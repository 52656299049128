import {
  CouponStateDispatchType,
  GetAllCoupons,
} from "@paperdateco/admin/store/coupons/CouponActions";

import CouponForm from "./CouponForm";
import routes from "@paperdateco/admin/routes/routes";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

export function CouponCreatePage() {
  const navigate = useNavigate();
  const dispatch = useDispatch<CouponStateDispatchType>();

  const onAddCoupon = useCallback(() => {
    dispatch(GetAllCoupons(true));
    navigate(routes.COUPONS);
  }, [dispatch, navigate]);

  return <CouponForm onSubmit={onAddCoupon} />;
}
