import {
  CategoryStateDispatchType,
  GetAllCategories,
} from "@paperdateco/admin/store/category/CategoryActions";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { GetAllProducts } from "@paperdateco/admin/store/products/ProductActions";
import ProductForm from "./ProductForm";
import { getAllDesigns } from "@paperdateco/admin/store/design/DesignActions";
import { getUsers } from "@paperdateco/admin/store/user/UserActions";
import routes from "@paperdateco/admin/routes/routes";
import { selectCategories } from "@paperdateco/admin/store/category/CategorySelector";
import { selectCollaborators } from "@paperdateco/admin/store/user/UserSelector";
import { selectDesigns } from "@paperdateco/admin/store/design/DesignSelector";
import { useNavigate } from "react-router-dom";

export default function ProductAddPage() {
  const categories = useSelector(selectCategories);
  const designs = useSelector(selectDesigns);
  const collaborators = useSelector(selectCollaborators);
  const dispatch = useDispatch<CategoryStateDispatchType>();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(GetAllCategories());
    dispatch(getAllDesigns());
    dispatch(getUsers());
  }, [dispatch]);

  const onAddProduct = useCallback(() => {
    dispatch(GetAllProducts(true));
    navigate(routes.PRODUCTS);
  }, [dispatch, navigate]);

  return (
    <ProductForm
      collaborators={collaborators}
      categories={categories}
      designs={designs}
      onSubmit={onAddProduct}
    />
  );
}
