import CustomCheckboxGroup, {
  CustomCheckboxOptionType,
} from "@paperdateco/shared-frontend/components/common/form/CustomCheckboxGroup";
import { FormEvent, useCallback, useEffect, useState } from "react";

import AdminUserUpdateRequestDto from "@paperdateco/common/dto/user/AdminUserUpdateRequestDto";
import BackButton from "@paperdateco/shared-frontend/components/common/form/button/BackButton";
import { Button } from "@mui/material";
import CustomCheckbox from "@paperdateco/shared-frontend/components/common/form/CustomCheckbox";
import CustomMediaPicker from "@paperdateco/shared-frontend/components/common/form/CustomMediaPicker";
import CustomTextField from "@paperdateco/shared-frontend/components/common/form/CustomTextField";
import DashboardContentLayout from "@paperdateco/shared-frontend/components/layout/DashboardContentLayout";
import FormContainer from "@paperdateco/shared-frontend/components/common/form/FormContainer";
import FormRow from "@paperdateco/shared-frontend/components/common/form/FormRow";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import UserApi from "@paperdateco/common/api/UserApi";
import UserDto from "@paperdateco/common/dto/user/UserDto";
import UserRole from "@paperdateco/common/dto/auth/UserRole";
import routes from "@paperdateco/admin/routes/routes";

const UserRoles: CustomCheckboxOptionType<UserRole>[] = [
  { name: "Customer", value: UserRole.CUSTOMER },
  { name: "Admin", value: UserRole.ADMIN },
  { name: "Collaborator", value: UserRole.COLLABORATOR },
];

interface UserFormProps {
  user?: UserDto;
  onSubmit?: (user: UserDto) => void;
}

export default function UserForm({ user, onSubmit }: UserFormProps) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [roles, setRoles] = useState<UserRole[]>([]);
  const [profilePicture, setProfilePicture] = useState<string>();
  const [bannerImage, setBannerImage] = useState<string>();

  useEffect(() => {
    setFirstName(user?.firstName ?? "");
    setLastName(user?.lastName ?? "");
    setRoles(user?.roles ?? []);
    setProfilePicture(user?.profilePicture);
    setBannerImage(user?.bannerImage);
  }, [user]);

  const getUploadedUrl = useCallback(
    async (file: File) => {
      if (!user) {
        return undefined;
      }
      return await UserApi.uploadMedia(user.id, file);
    },
    [user]
  );

  const onUploadProfilePicture = useCallback(
    async (file: File) => {
      const url = await getUploadedUrl(file);
      setProfilePicture(url);
    },
    [getUploadedUrl]
  );

  const onUploadBannerImage = useCallback(
    async (file: File) => {
      const url = await getUploadedUrl(file);
      setBannerImage(url);
    },
    [getUploadedUrl]
  );

  const onSubmitForm = async (e: FormEvent) => {
    e.preventDefault();
    const userRequest: AdminUserUpdateRequestDto = {
      firstName,
      lastName,
      roles,
      profilePicture,
      bannerImage,
    };
    if (!user) {
      return;
    }
    try {
      const newUser = await UserApi.updateOne(user.id, userRequest);
      onSubmit?.(newUser);
    } catch (e) {
      NotificationUtils.showGenericError(e);
    }
  };

  return (
    <DashboardContentLayout>
      <BackButton link={routes.USERS} name="Users" />
      <FormContainer onSubmit={onSubmitForm}>
        <FormRow>
          <CustomTextField
            value={firstName}
            onChange={setFirstName}
            label="First Name"
          />
        </FormRow>
        <FormRow>
          <CustomTextField
            value={lastName}
            onChange={setLastName}
            label="Last Name"
          />
        </FormRow>
        <FormRow>
          <CustomTextField value={user?.email ?? ""} label="Email" disabled />
        </FormRow>
        <FormRow>
          <CustomCheckbox
            checked={user?.emailVerified ?? false}
            label="Email Verified"
            disabled
          />
        </FormRow>
        <FormRow>
          <CustomCheckboxGroup
            label="User Roles"
            options={UserRoles}
            value={roles}
            onChange={setRoles}
          />
        </FormRow>
        <FormRow>
          <CustomMediaPicker
            label="Profile Picture"
            url={profilePicture}
            onUpload={onUploadProfilePicture}
          />
        </FormRow>
        <FormRow>
          <CustomMediaPicker
            label="Banner Image"
            url={bannerImage}
            onUpload={onUploadBannerImage}
          />
        </FormRow>
        <FormRow textAlign="center">
          <Button variant="contained" type="submit" size="large">
            UPDATE
          </Button>
        </FormRow>
      </FormContainer>
    </DashboardContentLayout>
  );
}
