import { ButtonBase, Stack, Typography, styled } from "@mui/material";

import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import { ComponentType } from "react";
import DashboardContentLayout from "@paperdateco/shared-frontend/components/layout/DashboardContentLayout";
import { Link } from "react-router-dom";
import WallpaperIcon from "@mui/icons-material/Wallpaper";
import routes from "@paperdateco/admin/routes/routes";

interface LibraryItem {
  name: string;
  description?: string;
  route: string;
  icon: ComponentType<{ color: any }>;
}

const LibraryItems: LibraryItem[] = [
  {
    name: "Images",
    icon: WallpaperIcon,
    route: routes.LIBRARY_IMAGES,
    description: "Add or update images in the design library",
  },
  {
    name: "Animation",
    icon: WallpaperIcon,
    route: routes.LIBRARY_ANIMATION,
    description: "Update animation in the design library",
  },
];

const ItemButton = styled(ButtonBase)(({ theme }) => ({
  border: `1px solid ${Colors.LIGHT_BORDER}`,
  borderRadius: theme.spacing(1),
  overflow: "hidden",
  padding: theme.spacing(2),
  textAlign: "left",
}));

interface LibraryItemComponentProps {
  item: LibraryItem;
}

function LibraryItemComponent({ item }: LibraryItemComponentProps) {
  return (
    <Link to={item.route}>
      <ItemButton>
        <Stack width={350} direction="row" spacing={2} alignItems="center">
          <item.icon color="inherit" />
          <Stack>
            <Typography variant="h6" fontWeight="bold">
              {item.name}
            </Typography>
            {item.description && (
              <Typography variant="body2">{item.description}</Typography>
            )}
          </Stack>
        </Stack>
      </ItemButton>
    </Link>
  );
}

export default function LibraryDashboardPage() {
  return (
    <DashboardContentLayout>
      <Typography variant="h5">Library</Typography>
      <Stack direction="row" flexWrap="wrap" spacing={3} marginTop={3}>
        {LibraryItems.map((item) => (
          <LibraryItemComponent item={item} key={item.name} />
        ))}
      </Stack>
    </DashboardContentLayout>
  );
}
