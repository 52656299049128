import {
  UserStateDispatchType,
  getUsers,
} from "@paperdateco/admin/store/user/UserActions";
import { useDispatch, useSelector } from "react-redux";

import Column from "@paperdateco/shared-frontend/components/table/Column";
import DashboardContentLayout from "@paperdateco/shared-frontend/components/layout/DashboardContentLayout";
import Table from "@paperdateco/shared-frontend/components/table/Table";
import UserDto from "@paperdateco/common/dto/user/UserDto";
import routes from "@paperdateco/admin/routes/routes";
import { selectUsers } from "@paperdateco/admin/store/user/UserSelector";
import useClickListener from "@paperdateco/shared-frontend/components/common/hooks/useClickListener";
import { useEffect } from "react";

export enum UserFieldNames {
  ID = "id",
  EMAIL = "email",
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  EMAIL_VERIFIED = "emailVerified",
  ROLES = "roles",
}

export const UserColumns: { [key in UserFieldNames]: Column<UserDto> } = {
  id: { title: "ID", field: "id" },
  email: { title: "Email", field: "email" },
  emailVerified: { title: "Email Verification", field: "emailVerified" },
  firstName: { title: "First Name", field: "firstName" },
  lastName: { title: "Last Name", field: "lastName" },
  roles: {
    title: "Roles",
    field: "roles",
    render: (user) => user.roles.join(", "),
  },
};

const columns: Column<UserDto>[] = [
  UserColumns.email,
  UserColumns.firstName,
  UserColumns.emailVerified,
  UserColumns.roles,
];

const allColumns = Object.values(UserColumns);

export default function UsersListPage() {
  const users = useSelector(selectUsers);

  const dispatch = useDispatch<UserStateDispatchType>();

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const goToEditUser = useClickListener((user?: UserDto) => {
    if (user?.id) {
      return routes.USERS_EDIT(user.id);
    }
  });

  return (
    <DashboardContentLayout>
      <Table
        title="Users list"
        data={users}
        columns={columns}
        allColumns={allColumns}
        onRowClick={goToEditUser}
      />
    </DashboardContentLayout>
  );
}
