import { Box, Typography } from "@mui/material";

import routes from "@paperdateco/admin/routes/routes";
import useLogout from "@paperdateco/shared-frontend/components/common/hooks/useLogout";

export default function LogoutPage() {
  useLogout(routes.LOGIN);

  return (
    <Box>
      <Typography variant="body1">Logging Out...</Typography>
    </Box>
  );
}
