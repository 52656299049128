import {
  CouponStateDispatchType,
  GetAllCoupons,
} from "@paperdateco/admin/store/coupons/CouponActions";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import CouponApi from "@paperdateco/common/api/CouponApi";
import CouponDto from "@paperdateco/common/dto/coupon/CouponDto";
import CouponForm from "./CouponForm";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import routes from "@paperdateco/admin/routes/routes";
import { useDispatch } from "react-redux";

export function CouponEditPage() {
  const { couponId } = useParams<{ couponId: string }>();
  const [coupon, setCoupon] = useState<CouponDto>();

  const dispatch = useDispatch<CouponStateDispatchType>();
  const navigate = useNavigate();

  const onEditCoupon = useCallback(() => {
    dispatch(GetAllCoupons(true));
    navigate(routes.COUPONS);
  }, [navigate, dispatch]);

  useEffect(() => {
    if (!couponId) {
      return;
    }
    CouponApi.getCouponDetail(couponId)
      .then(setCoupon)
      .catch(NotificationUtils.showGenericError);
  }, [couponId]);

  return <CouponForm coupon={coupon} onSubmit={onEditCoupon} />;
}
