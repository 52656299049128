import {
  DesignStateDispatchType,
  getAllDesigns,
} from "@paperdateco/admin/store/design/DesignActions";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { GetAllCategories } from "@paperdateco/admin/store/category/CategoryActions";
import { GetAllProducts } from "@paperdateco/admin/store/products/ProductActions";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import ProductApi from "@paperdateco/common/api/product/ProductApi";
import ProductDto from "@paperdateco/common/dto/product/ProductDto";
import ProductForm from "./ProductForm";
import { getUsers } from "@paperdateco/admin/store/user/UserActions";
import routes from "@paperdateco/admin/routes/routes";
import { selectCategories } from "@paperdateco/admin/store/category/CategorySelector";
import { selectCollaborators } from "@paperdateco/admin/store/user/UserSelector";
import { selectDesigns } from "@paperdateco/admin/store/design/DesignSelector";

export default function ProductEditPage() {
  const categories = useSelector(selectCategories);
  const designs = useSelector(selectDesigns);
  const collaborators = useSelector(selectCollaborators);
  const { productId } = useParams<{ productId: string }>();
  const [product, setProduct] = useState<ProductDto>();
  const dispatch = useDispatch<DesignStateDispatchType>();
  const navigate = useNavigate();

  const onEditProduct = useCallback(() => {
    dispatch(GetAllProducts(true));
    navigate(routes.PRODUCTS);
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(GetAllCategories());
    dispatch(getAllDesigns());
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    if (!productId) {
      return;
    }
    ProductApi.getProductById(productId)
      .then(setProduct)
      .catch(NotificationUtils.showGenericError);
  }, [productId]);

  return (
    <ProductForm
      categories={categories}
      designs={designs}
      collaborators={collaborators}
      product={product}
      onSubmit={onEditProduct}
    />
  );
}
