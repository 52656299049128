import {
  DesignStateDispatchType,
  getAllDesigns,
} from "@paperdateco/admin/store/design/DesignActions";
import { useDispatch, useSelector } from "react-redux";

import LibraryImageFormPage from "@paperdateco/shared-frontend/pages/libraryImage/LibraryImageFormPage";
import { getAllLibraryImages } from "@paperdateco/admin/store/libraryImage/LibraryImageAction";
import routes from "@paperdateco/admin/routes/routes";
import { selectDesigns } from "@paperdateco/admin/store/design/DesignSelector";
import { useCallback } from "react";
import { useNavigate } from "react-router";

export default function LibraryImageAddPage() {
  const dispatch = useDispatch<DesignStateDispatchType>();
  const navigate = useNavigate();
  const designs = useSelector(selectDesigns);

  const onSubmit = useCallback(() => {
    dispatch(getAllLibraryImages(true));
    dispatch(getAllDesigns());
    navigate(routes.LIBRARY_IMAGES);
  }, [dispatch, navigate]);

  return (
    <LibraryImageFormPage
      isAdmin
      designs={designs}
      onSubmit={onSubmit}
      backLink={routes.LIBRARY_IMAGES}
    />
  );
}
