import Column from "../../table/Column";
import LibraryAnimationDto from "@paperdateco/common/dto/design/library/animation/LibraryAnimationDto";

enum LibraryAnimationFieldNames {
  ID = "id",
  ANIMATIONTYPE = "animationType",
  NAME = "name",
  PRICE = "price",
  ICON = "icon",
}

const LibraryAnimationColumns: {
  [key in LibraryAnimationFieldNames]: Column<LibraryAnimationDto>;
} = {
  id: { title: "ID", field: "id" },
  animationType: { title: "AnimationyType", field: "animationType" },
  name: { title: "Name", field: "name" },
  price: { title: "Price", field: "price" },
  icon: {
    title: "Icon",
    field: "icon",
    render: (item) => <img src={item.url} alt={item.name} width={100} />,
  },
};

export default LibraryAnimationColumns;
