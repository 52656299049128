import { Button, Stack } from "@mui/material";
import { FormEvent, useCallback, useEffect, useState } from "react";

import CustomMediaPicker from "@paperdateco/shared-frontend/components/common/form/CustomMediaPicker";
import CustomNumberInput from "@paperdateco/shared-frontend/components/common/form/CustomNumberInput";
import CustomTextField from "@paperdateco/shared-frontend/components/common/form/CustomTextField";
import DesignApi from "@paperdateco/common/api/DesignApi";
import DesignLibraryAnimationApi from "@paperdateco/common/api/design/DesignLibraryAnimationApi";
import FormContainer from "@paperdateco/shared-frontend/components/common/form/FormContainer";
import FormRow from "@paperdateco/shared-frontend/components/common/form/FormRow";
import LibraryAnimationDto from "@paperdateco/common/dto/design/library/animation/LibraryAnimationDto";
import LibraryAnimationRequestDto from "@paperdateco/common/dto/design/library/animation/LibraryAnimationRequestDto";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";

interface LibraryAnimationFormProps {
  isAdmin?: boolean;
  libraryAnimation: LibraryAnimationDto;
  onSubmit?: (libraryAnimation: LibraryAnimationDto) => void;
}

export default function LibraryAnimationForm({
  isAdmin,
  libraryAnimation,
  onSubmit,
}: LibraryAnimationFormProps) {
  const [animationType, setAnimationType] = useState(
    libraryAnimation.animationType
  );
  const [name, setName] = useState("");
  const [url, setUrl] = useState<string>();
  const [price, setPrice] = useState(0);

  useEffect(() => {
    setAnimationType(libraryAnimation.animationType);
    setName(libraryAnimation.name);
    setUrl(libraryAnimation.url);
    setPrice(libraryAnimation.price);
  }, [libraryAnimation]);

  const onUpload = useCallback(async (file: File) => {
    const url = await DesignApi.uploadMedia(file);
    setUrl(url);
  }, []);

  const onSubmitForm = async (e: FormEvent) => {
    e.preventDefault();
    if (!url) {
      NotificationUtils.showError("Please upload a file");
      return;
    }
    const request: LibraryAnimationRequestDto = {
      animationType,
      name,
      url,
      price,
    };
    try {
      const newLibraryAnimation =
        await DesignLibraryAnimationApi.updateLibraryAnimation(
          libraryAnimation.id,
          request
        );
      onSubmit?.(newLibraryAnimation);
    } catch (e) {
      NotificationUtils.showGenericError(e);
    }
  };

  const onHideAnimation = () => {
    if (!libraryAnimation) {
      return;
    }
    DesignLibraryAnimationApi.hideLibraryAnimation(libraryAnimation.id)
      .then(onSubmit)
      .catch(NotificationUtils.showGenericError);
  };

  return (
    <FormContainer onSubmit={onSubmitForm}>
      <FormRow>
        <CustomTextField value={name} onChange={setName} label="Name" />
      </FormRow>
      <FormRow display="flex" alignItems="center">
        <CustomMediaPicker url={url} onUpload={onUpload} />
      </FormRow>

      {isAdmin && (
        <>
          <FormRow>
            <CustomNumberInput
              value={price}
              onChange={setPrice}
              label="Price"
              inputProps={{ step: 0.01 }}
            />
          </FormRow>
        </>
      )}
      <FormRow textAlign="center">
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Button variant="contained" type="submit" size="large">
            {libraryAnimation ? "UPDATE" : "ADD"}
          </Button>

          {isAdmin && libraryAnimation?.visible && (
            <Button
              variant="contained"
              color="error"
              type="button"
              size="large"
              onClick={onHideAnimation}
            >
              HIDE
            </Button>
          )}
        </Stack>
      </FormRow>
    </FormContainer>
  );
}
