import {
  CategoryStateDispatchType,
  GetAllCategories,
} from "@paperdateco/admin/store/category/CategoryActions";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import routes from "@paperdateco/admin/routes/routes";
import { selectParentCategories } from "@paperdateco/admin/store/category/CategorySelector";
import { useNavigate } from "react-router";

export default function useCategoryFormState() {
  const categories = useSelector(selectParentCategories);
  const dispatch = useDispatch<CategoryStateDispatchType>();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(GetAllCategories());
  }, [dispatch]);

  const onSubmit = useCallback(() => {
    dispatch(GetAllCategories(true));
    navigate(routes.CATEGORIES);
  }, [dispatch, navigate]);

  return { categories, onSubmit };
}
