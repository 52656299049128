import Column from "../../table/Column";
import DesignUtils from "@paperdateco/common/utils/DesignUtils";
import ProductDto from "@paperdateco/common/dto/product/ProductDto";

enum ProductFieldNames {
  ID = "id",
  NAME = "name",
  IMAGE = "image",
  PRICE = "price",
  DESCRIPTION = "description",
  DESIGNER_NAME = "designerName",
  CATEGORIES = "categories",
  TAGS = "tags",
}

const ProductColumns: {
  [key in ProductFieldNames]: Column<ProductDto>;
} = {
  id: {
    title: "Id",
    field: "id",
    render: (product) => product.id,
  },
  name: {
    title: "Name",
    field: "name",
    render: (product) => product.name,
  },
  image: {
    title: "Image",
    field: "image",
    render: (data) => (
      <img
        width={100}
        src={DesignUtils.getImageFromProduct(data)}
        alt={data.name}
      />
    ),
  },
  price: {
    title: "Price",
    field: "price",
    render: (product) => product.price,
  },
  description: {
    title: "Description",
    field: "description",
    render: (product) => product.description,
  },
  designerName: {
    title: "Designer Name",
    field: "designerName",
    render: (product) => product.designedBy?.name,
  },
  tags: {
    title: "Tags",
    field: "tags",
    render: (product) => product.tags.join(", "),
  },
  categories: {
    title: "Categories",
    field: "categories",
    render: (data) =>
      data.categories.map((category) => category.name).join(", "),
  },
};

export default ProductColumns;
