import DashboardContentLayout from "@paperdateco/shared-frontend/components/layout/DashboardContentLayout";
import { Typography } from "@mui/material";

export default function Homepage() {
  return (
    <DashboardContentLayout>
      <Typography variant="body1">Welcome to Admin Portal</Typography>
    </DashboardContentLayout>
  );
}
