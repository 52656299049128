import { Button, Stack } from "@mui/material";
import { useEffect, useState } from "react";

import DashboardContentLayout from "@paperdateco/shared-frontend/components/layout/DashboardContentLayout";
import FeedItemDto from "@paperdateco/common/dto/instagramFeed/FeedItemDto";
import InstagramFeedApi from "@paperdateco/common/api/instagramFeedApi";
import InstagramTable from "../../table/InstagramTable";

export default function InstagramListPage() {
  const [instaFeeds, setInstaFeeds] = useState<FeedItemDto[]>([]);

  useEffect(() => {
    InstagramFeedApi.getAllInstagramFeeds().then(setInstaFeeds);
  }, []);

  const updateFeeds = () => {
    InstagramFeedApi.updateInstagramFeeds().then(setInstaFeeds);
  };
  return (
    <DashboardContentLayout>
      <Stack alignItems={"flex-end"} width="100%" marginBottom={2}>
        <Button variant="contained" size="large" onClick={updateFeeds}>
          Update Instagram Feeds
        </Button>
      </Stack>
      <InstagramTable feeds={instaFeeds} />
    </DashboardContentLayout>
  );
}
