import { useEffect, useState } from "react";

import CategoryApi from "@paperdateco/common/api/product/CategoryApi";
import CategoryDto from "@paperdateco/common/dto/category/CategoryDto";
import CategoryForm from "./CategoryForm";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import useCategoryFormState from "./useCategoryFormState";
import { useParams } from "react-router-dom";

export default function CategoryEditPage() {
  const { categoryId } = useParams<{ categoryId: string }>();
  const [category, setCategory] = useState<CategoryDto>();

  const { categories, onSubmit } = useCategoryFormState();

  useEffect(() => {
    if (!categoryId) {
      return;
    }
    CategoryApi.getCategory(categoryId)
      .then(setCategory)
      .catch(NotificationUtils.showGenericError);
  }, [categoryId]);

  return (
    <CategoryForm
      categories={categories}
      category={category}
      onSubmit={onSubmit}
    />
  );
}
