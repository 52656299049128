import { Box, Toolbar, Typography } from "@mui/material";

import { CustomStyled } from "@paperdateco/admin/utils/styles/DefaultTheme";
import logo from "@paperdateco/shared-frontend/assets/images/logo/small_logo.png";

const CustomToolbar = CustomStyled(Toolbar)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(2, 0),
}));

export default function LoggedOutHeader() {
  return (
    <CustomToolbar>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="center"
        width="100%"
      >
        <img src={logo} alt="Logo" width={70} />
        <Typography variant="h5" color="primary" textAlign="center" marginX={2}>
          Paper Date Co. Admin Panel
        </Typography>
      </Box>
    </CustomToolbar>
  );
}
