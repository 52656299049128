import Column from "../../table/Column";
import CouponDto from "@paperdateco/common/dto/coupon/CouponDto";
import StringUtils from "@paperdateco/common/utils/StringUtils";

export enum CouponFieldNames {
  ID = "id",
  NAME = "name",
  TYPE = "type",
  VALUE = "value",
  PER_USER_LIMIT = "perUserLimit",
  VALIDITY = "validity",
}

const CouponColumns: { [key in CouponFieldNames]: Column<CouponDto> } = {
  id: { title: "ID", field: "id" },
  name: { title: "Name", field: "name" },
  type: { title: "Type", field: "type" },
  value: { title: "Value", field: "value" },
  perUserLimit: { title: "User Limit", field: "perUserLimit" },
  validity: {
    title: "Validity",
    field: "validity",
    render: (coupon) => StringUtils.formatDate(coupon.validity),
  },
};

export default CouponColumns;
