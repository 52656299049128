import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

import AdminDesignAddPage from "../components/pages/design/AdminDesignAddPage";
import AdminDesignEditPage from "../components/pages/design/AdminDesignEditPage";
import AdminLoginForm from "../components/auth/AdminLoginForm";
import AdminRoute from "@paperdateco/shared-frontend/routes/guards/AdminRoute";
import BannerAddPage from "../components/pages/banner/BannerAddPage";
import BannerEditPage from "../components/pages/banner/BannerEditPage";
import Bannerpage from "../components/pages/banner/BannerPage";
import BannersListPage from "../components/pages/banner/BannersListPage";
import CategoriesListPage from "../components/pages/category/CategoriesListPage";
import CategoryAddPage from "../components/pages/category/CategoryAddPage";
import CategoryEditPage from "../components/pages/category/CategoryEditPage";
import Categorypage from "../components/pages/category/CategoryPage";
import { CouponCreatePage } from "../components/pages/coupon/CouponCreatePage";
import { CouponEditPage } from "../components/pages/coupon/CouponEditPage";
import CouponListPage from "../components/pages/coupon/CouponListPage";
import CouponsPage from "../components/pages/coupon/CouponsPage";
import DesignListPage from "../components/pages/design/DesignListPage";
import DesignPage from "../components/pages/design/DesignPage";
import Homepage from "../components/pages/HomePage";
import InstagramListPage from "../components/pages/instagram/InstagramListPage";
import LibraryAnimationPage from "../components/pages/library/animation/LibraryAnimationPage";
import LibraryDashboardPage from "../components/pages/library/LibraryDashboardPage";
import LibraryImageAddPage from "../components/pages/library/image/LibraryImageAddPage";
import LibraryImageEditPage from "../components/pages/library/image/LibraryImageEditPage";
import LibraryImagePage from "../components/pages/library/image/LibraryImagePage";
import LibraryPage from "../components/pages/library/LibraryPage";
import LogoutPage from "../components/pages/auth/LogoutPage";
import { ModalContext } from "@paperdateco/shared-frontend/components/providers/ModalProvider";
import OrdersPage from "../components/pages/OrdersPage";
import PortalPage from "../components/pages/PortalPage";
import ProductAddPage from "../components/pages/product/ProductAddPage";
import ProductEditPage from "../components/pages/product/ProductEditPage";
import ProductsListPage from "../components/pages/product/ProductsListPage";
import ProductsPage from "../components/pages/product/ProductsPage";
import ScrollToTop from "@paperdateco/shared-frontend/routes/ScrollToTop";
import UserEditPage from "../components/pages/users/UserEditPage";
import UserPage from "../components/pages/users/UserPage";
import UsersListPage from "../components/pages/users/UsersListPage";
import routes from "./routes";
import { useContext } from "react";

export default function AppRouter() {
  const { popup } = useContext(ModalContext);

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route
          path={routes.PORTAL}
          element={
            <AdminRoute redirectTo={routes.LOGIN}>
              <PortalPage />
            </AdminRoute>
          }
        >
          <Route index element={<Navigate to={routes.DASHBOARD} replace />} />
          <Route path={routes.DASHBOARD} element={<Homepage />} />
          <Route path={routes.BANNERS} element={<Bannerpage />}>
            <Route path={routes.BANNERS} element={<BannersListPage />} />
            <Route path={routes.BANNERS_ADD} element={<BannerAddPage />} />
            <Route path={routes.BANNERS_EDIT()} element={<BannerEditPage />} />
          </Route>
          <Route path={routes.CATEGORIES} element={<Categorypage />}>
            <Route path={routes.CATEGORIES} element={<CategoriesListPage />} />
            <Route path={routes.CATEGORIES_ADD} element={<CategoryAddPage />} />
            <Route
              path={routes.CATEGORIES_EDIT()}
              element={<CategoryEditPage />}
            />
          </Route>
          <Route path={routes.COUPONS} element={<CouponsPage />}>
            <Route path={routes.COUPONS} element={<CouponListPage />} />
            <Route path={routes.COUPONS_ADD} element={<CouponCreatePage />} />
            <Route path={routes.COUPONS_EDIT()} element={<CouponEditPage />} />
          </Route>
          <Route path={routes.DESIGNS} element={<DesignPage />}>
            <Route path={routes.DESIGNS} element={<DesignListPage />} />
            <Route path={routes.DESIGN_ADD} element={<AdminDesignAddPage />} />
            <Route
              path={routes.DESIGN_EDIT()}
              element={<AdminDesignEditPage />}
            />
          </Route>
          <Route path={routes.INSTAGRAM} element={<InstagramListPage />} />
          <Route path={routes.LIBRARY} element={<LibraryPage />}>
            <Route path={routes.LIBRARY} element={<LibraryDashboardPage />} />
            <Route
              path={routes.LIBRARY_IMAGES}
              element={<LibraryImagePage />}
            />
            <Route
              path={routes.LIBRARY_IMAGES_ADD}
              element={<LibraryImageAddPage />}
            />
            <Route
              path={routes.LIBRARY_IMAGES_EDIT()}
              element={<LibraryImageEditPage />}
            />
            <Route
              path={routes.LIBRARY_ANIMATION}
              element={<LibraryAnimationPage />}
            />
          </Route>
          <Route path={routes.ORDERS} element={<OrdersPage />} />
          <Route path={routes.PRODUCTS} element={<ProductsPage />}>
            <Route path={routes.PRODUCTS} element={<ProductsListPage />} />
            <Route path={routes.PRODUCTS_ADD} element={<ProductAddPage />} />
            <Route
              path={routes.PRODUCTS_EDIT()}
              element={<ProductEditPage />}
            />
          </Route>
          <Route path={routes.USERS} element={<UserPage />}>
            <Route path={routes.USERS} element={<UsersListPage />} />
            <Route path={routes.USERS_EDIT()} element={<UserEditPage />} />
          </Route>
          <Route path={routes.LOGOUT} element={<LogoutPage />} />
        </Route>
        <Route path={routes.LOGIN} element={<AdminLoginForm />} />
        <Route path="*" element={<Navigate to={routes.LOGIN} replace />} />
      </Routes>
      {popup}
    </Router>
  );
}
