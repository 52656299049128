import DesignLibraryAnimationApi from "@paperdateco/common/api/design/DesignLibraryAnimationApi";
import GetItemsAction from "@paperdateco/shared-frontend/store/getItems/GetItemsAction";
import GetItemsActionTypes from "@paperdateco/shared-frontend/store/getItems/GetItemsTypes";
import { LIBRARY_ANIMATIONS } from "@paperdateco/shared-frontend/store/libraryAnimation/LibraryAnimationTypes";
import LibraryAnimationDto from "@paperdateco/common/dto/design/library/animation/LibraryAnimationDto";
import { RootState } from "..";
import { ThunkDispatch } from "redux-thunk";

export const getAllLibraryAnimations = GetItemsAction(
  LIBRARY_ANIMATIONS,
  DesignLibraryAnimationApi.getLibraryAnimations,
  (state: RootState) => state.libraryAnimations
);

export type LibraryAnimationStateDispatchType = ThunkDispatch<
  RootState,
  unknown,
  GetItemsActionTypes<LibraryAnimationDto>
>;
