import { Box, Typography } from "@mui/material";

import Column from "../../table/Column";
import FeedItemDto from "@paperdateco/common/dto/instagramFeed/FeedItemDto";
import ForegroundSelect from "../../common/image/ForegroundSelect";

enum FeedFieldNames {
  ID = "id",
  POST = "feed",
  DESCRIPTION = "description",
  DATE = "date",
}

const FeedColumns: { [key in FeedFieldNames]: Column<FeedItemDto> } = {
  id: { title: "ID", field: "feedId" },
  feed: {
    title: "Feed",
    field: "feed",
    render: (data) => (
      <ForegroundSelect
        src={data.image}
        alt={data.feedId}
        type={"image"}
        customWidth="100"
      />
    ),
  },
  description: {
    title: "Description",
    field: "description",
    render: (data) => (
      <Box width={150}>
        <Typography
          //https://stackoverflow.com/a/71324014
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "3",
            WebkitBoxOrient: "vertical",
          }}
        >
          {data.description}
        </Typography>
      </Box>
    ),
  },
  date: { title: "Date", field: "date" },
};

export default FeedColumns;
