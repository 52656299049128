const LOGIN = "/";
const PORTAL = "/portal";
const DASHBOARD = PORTAL + "/dashboard";
const CATEGORIES = PORTAL + "/categories";
const CATEGORIES_ADD = CATEGORIES + "/new";
const CATEGORIES_EDIT = (categoryId = ":categoryId") =>
  CATEGORIES + "/" + categoryId + "/edit";
const BANNERS = PORTAL + "/banners";
const BANNERS_ADD = BANNERS + "/new";
const BANNERS_EDIT = (bannerId = ":bannerId") =>
  BANNERS + "/" + bannerId + "/edit";
const USERS = PORTAL + "/users";
const USERS_EDIT = (userId = ":userId") => USERS + "/" + userId + "/edit";
const ORDERS = PORTAL + "/orders";
const DESIGNS = PORTAL + "/designs";
const COUPONS = PORTAL + "/coupons";
const INSTAGRAM = PORTAL + "/instagram";
const COUPONS_ADD = COUPONS + "/new";
const COUPONS_EDIT = (couponId = ":couponId") =>
  COUPONS + "/" + couponId + "/edit";
const PRODUCTS = PORTAL + "/products";
const PRODUCTS_ADD = PRODUCTS + "/new";
const PRODUCTS_EDIT = (productId = ":productId") =>
  PRODUCTS + "/" + productId + "/edit";
const DESIGN_ADD = DESIGNS + "/new";
const DESIGN_EDIT = (designId = ":designId") =>
  DESIGNS + "/" + designId + "/edit";

const LIBRARY = PORTAL + "/library";
const LIBRARY_IMAGES = LIBRARY + "/images";
const LIBRARY_IMAGES_ADD = LIBRARY_IMAGES + "/add";
const LIBRARY_IMAGES_EDIT = (imageId = ":imageId") =>
  LIBRARY_IMAGES + "/edit/" + imageId;
const LIBRARY_ANIMATION = LIBRARY + "/animation";
const LOGOUT = PORTAL + "/logout";

const routes = {
  PORTAL,
  LOGIN,
  DASHBOARD,
  CATEGORIES,
  CATEGORIES_ADD,
  CATEGORIES_EDIT,
  BANNERS,
  BANNERS_ADD,
  BANNERS_EDIT,
  USERS,
  USERS_EDIT,
  ORDERS,
  INSTAGRAM,
  COUPONS,
  COUPONS_ADD,
  COUPONS_EDIT,
  PRODUCTS,
  PRODUCTS_ADD,
  PRODUCTS_EDIT,
  DESIGNS,
  DESIGN_ADD,
  DESIGN_EDIT,
  LIBRARY,
  LIBRARY_IMAGES,
  LIBRARY_IMAGES_ADD,
  LIBRARY_IMAGES_EDIT,
  LIBRARY_ANIMATION,
  LOGOUT,
};

export default routes;
