import GetItemsReducer from "@paperdateco/shared-frontend/store/getItems/GetItemsReducer";
import { ORDER_GET_ITEMS_NAMES } from "./OrderTypes";
import OrderDetailedDto from "@paperdateco/common/dto/order/OrderDetailedDto";

const OrderReducer = GetItemsReducer<OrderDetailedDto[]>(
  ORDER_GET_ITEMS_NAMES,
  []
);

export default OrderReducer;
