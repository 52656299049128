import { Button, Stack } from "@mui/material";
import { FormEvent, useCallback, useEffect, useState } from "react";

import AdminBannerUpdateRequestDto from "@paperdateco/common/dto/banner/AdminBannerUpdateRequest";
import BackButton from "@paperdateco/shared-frontend/components/common/form/button/BackButton";
import BannerApi from "@paperdateco/common/api/bannerApi";
import BannerDto from "@paperdateco/common/dto/banner/BannerDto";
import CustomMediaPicker from "@paperdateco/shared-frontend/components/common/form/CustomMediaPicker";
import CustomNumberInput from "@paperdateco/shared-frontend/components/common/form/CustomNumberInput";
import CustomTextField from "@paperdateco/shared-frontend/components/common/form/CustomTextField";
import DashboardContentLayout from "@paperdateco/shared-frontend/components/layout/DashboardContentLayout";
import FormContainer from "@paperdateco/shared-frontend/components/common/form/FormContainer";
import FormRow from "@paperdateco/shared-frontend/components/common/form/FormRow";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import UploadTypes from "@paperdateco/common/dto/common/UploadTypes";
import routes from "@paperdateco/admin/routes/routes";

interface BannerFormProps {
  banner?: BannerDto;
  onSubmit?: (banner: BannerDto) => void;
}

export default function BannerForm({ banner, onSubmit }: BannerFormProps) {
  const [name, setName] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [bannerWidth, setBannerWidth] = useState(0);
  const [bannerHeight, setBannerHeight] = useState(0);
  const [bannerType, setBannerType] = useState(UploadTypes.IMAGE);
  const [textImage, setTextImage] = useState("");
  const [textWidth, setTextWidth] = useState(0);
  const [textHeight, setTextHeight] = useState(0);
  const [textType, setTextType] = useState(UploadTypes.IMAGE);
  const [buttonText, setButtonText] = useState("");
  const [buttonLink, setButtonLink] = useState("");

  useEffect(() => {
    setName(banner?.name ?? "");
    setBannerImage(banner?.bannerImage ?? "");
    setBannerType(banner?.bannerType ?? UploadTypes.IMAGE);
    setBannerWidth(banner?.bannerWidth ?? 0);
    setBannerHeight(banner?.bannerHeight ?? 0);
    setTextImage(banner?.textImage ?? "");
    setTextType(banner?.textType ?? UploadTypes.IMAGE);
    setTextWidth(banner?.textWidth ?? 0);
    setTextHeight(banner?.textHeight ?? 0);
    setButtonText(banner?.buttonText ?? "");
    setButtonLink(banner?.buttonLink ?? "");
  }, [banner]);

  const onUpdate = useCallback(
    (newBanner: BannerDto) => {
      onSubmit?.(newBanner);
    },
    [onSubmit]
  );

  const onUploadBanner = useCallback(async (file: File) => {
    const { url, type, width, height } = await BannerApi.uploadBannerMedia(
      file
    );
    setBannerWidth(width);
    setBannerHeight(height);
    setBannerImage(url);
    setBannerType(type);
  }, []);

  const onUploadText = useCallback(async (file: File) => {
    const { url, type, width, height } = await BannerApi.uploadBannerMedia(
      file
    );
    setTextWidth(width);
    setTextHeight(height);
    setTextImage(url);
    setTextType(type);
  }, []);

  const onHideImage = () => {
    if (!banner) {
      return;
    }
    BannerApi.hideBanner(banner.id)
      .then(onUpdate)
      .catch(NotificationUtils.showGenericError);
  };

  const onSubmitForm = async (e: FormEvent) => {
    e.preventDefault();

    const request: AdminBannerUpdateRequestDto = {
      name,
      bannerImage,
      bannerWidth,
      bannerHeight,
      bannerType,
      textImage,
      textWidth,
      textHeight,
      textType,
      buttonText,
      buttonLink,
      visible: true,
    };

    try {
      const newBanner = banner?.id
        ? await BannerApi.updateBanner(banner.id, request)
        : await BannerApi.addBanner(request);
      onUpdate(newBanner);
    } catch (e) {
      NotificationUtils.showGenericError(e);
    }
  };

  return (
    <DashboardContentLayout>
      <BackButton link={routes.BANNERS} name="BANNERS" />
      <FormContainer onSubmit={onSubmitForm}>
        <FormRow>
          <CustomTextField value={name} onChange={setName} label="Name" />
        </FormRow>

        <FormRow display="flex" alignItems="center">
          <CustomMediaPicker
            label={"Banner Media"}
            url={bannerImage}
            type={bannerType}
            onUpload={onUploadBanner}
            width={150}
          />
        </FormRow>

        <FormRow>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
          >
            <CustomNumberInput
              value={bannerWidth}
              onChange={setBannerWidth}
              label="Banner Width"
            />
            <CustomNumberInput
              value={bannerHeight}
              type="number"
              onChange={setBannerHeight}
              label="Banner Height"
            />
          </Stack>
        </FormRow>

        <FormRow display="flex" alignItems="center">
          <CustomMediaPicker
            label={"Text Media"}
            url={textImage}
            type={textType}
            onUpload={onUploadText}
            width={150}
          />
        </FormRow>

        <FormRow>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
          >
            <CustomNumberInput
              value={textWidth}
              onChange={setTextWidth}
              label="Text Width"
            />
            <CustomNumberInput
              value={textHeight}
              type="number"
              onChange={setTextHeight}
              label="Text Height"
            />
          </Stack>
        </FormRow>

        <FormRow>
          <CustomTextField
            value={buttonText}
            onChange={setButtonText}
            label="Button Text"
          />
        </FormRow>
        <FormRow>
          <CustomTextField
            value={buttonLink}
            onChange={setButtonLink}
            label="Button Link"
          />
        </FormRow>

        <FormRow textAlign="center">
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Button variant="contained" type="submit" size="large">
              {banner ? "UPDATE" : "ADD"}
            </Button>

            {banner?.visible && (
              <Button
                variant="contained"
                type="button"
                size="large"
                onClick={onHideImage}
              >
                HIDE
              </Button>
            )}
          </Stack>
        </FormRow>
      </FormContainer>
    </DashboardContentLayout>
  );
}
