import { FormEvent, useCallback, useEffect, useState } from "react";

import BackButton from "@paperdateco/shared-frontend/components/common/form/button/BackButton";
import { Button } from "@mui/material";
import CategoryDto from "@paperdateco/common/dto/category/CategoryDto";
import CustomAutocomplete from "@paperdateco/shared-frontend/components/common/form/CustomAutoComplete";
import CustomCheckbox from "@paperdateco/shared-frontend/components/common/form/CustomCheckbox";
import CustomMediaPicker from "@paperdateco/shared-frontend/components/common/form/CustomMediaPicker";
import CustomNumberInput from "@paperdateco/shared-frontend/components/common/form/CustomNumberInput";
import CustomTextField from "@paperdateco/shared-frontend/components/common/form/CustomTextField";
import DashboardContentLayout from "@paperdateco/shared-frontend/components/layout/DashboardContentLayout";
import DesignApi from "@paperdateco/common/api/DesignApi";
import DesignDto from "@paperdateco/common/dto/design/DesignDto";
import FormContainer from "@paperdateco/shared-frontend/components/common/form/FormContainer";
import FormRow from "@paperdateco/shared-frontend/components/common/form/FormRow";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import ProductApi from "@paperdateco/common/api/product/ProductApi";
import ProductDto from "@paperdateco/common/dto/product/ProductDto";
import ProductRequestDto from "@paperdateco/common/dto/product/ProductRequestDto";
import StringUtils from "@paperdateco/common/utils/StringUtils";
import UserDto from "@paperdateco/common/dto/user/UserDto";
import routes from "@paperdateco/admin/routes/routes";

interface ProductFormProps {
  product?: ProductDto;
  categories: CategoryDto[];
  designs: DesignDto[];
  collaborators: UserDto[];
  onSubmit?: (product: ProductDto) => void;
}

export default function ProductForm({
  product,
  categories: productCategories,
  designs,
  collaborators,
  onSubmit,
}: ProductFormProps) {
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [image, setImage] = useState("");
  const [useCustomImage, setUseCustomImage] = useState(false);
  const [price, setPrice] = useState(0);
  const [description, setDescription] = useState("");
  const [design, setDesign] = useState<string>();
  const [designedBy, setDesignedBy] = useState<string>();
  const [tags, setTags] = useState<string[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const filledSlug = StringUtils.useWhenEmpty(
    StringUtils.encodeAsUri(name),
    slug
  );

  const categoryOptions = productCategories.map((category) => category.id);
  const categoryLabel = useCallback(
    (id: string) =>
      productCategories.find((category) => category.id === id)?.name ?? "",
    [productCategories]
  );

  const designOptions = designs.map((design) => design.id);
  const designLabel = useCallback(
    (id: string) => designs.find((d) => d.id === id)?.name ?? "",
    [designs]
  );

  const designerOptions = collaborators.map((collaborator) => collaborator.id);
  const designerLabel = useCallback(
    (id: string) => collaborators.find((d) => d.id === id)?.name ?? "",
    [collaborators]
  );

  const onUploadImage = useCallback(async (file: File) => {
    const url = await DesignApi.uploadMedia(file);
    setImage(url);
  }, []);

  useEffect(() => {
    setName(product?.name ?? "");
    setSlug(product?.slug ?? "");
    setImage(product?.image ?? "");
    setUseCustomImage(product?.useCustomImage ?? false);
    setPrice(product?.price ?? 0);
    setDescription(product?.description ?? "");
    setDesignedBy(product?.designedBy?.id);
    setDesign(product?.design.id);
    setTags(product?.tags ?? []);
    setCategories(product?.categories.map((c) => c.id) ?? []);
  }, [product]);

  useEffect(() => {
    if (!designedBy) {
      const n = designs.find((d) => d.id === design)?.designer?.id;
      if (n) {
        setDesignedBy(n);
      }
    }
  }, [design, designs, designedBy]);

  const onSubmitForm = async (e: FormEvent) => {
    e.preventDefault();
    if (!design) {
      NotificationUtils.showError("Please select a valid design");
      return;
    }
    if (!designedBy) {
      NotificationUtils.showError("Please select a valid designer");
      return;
    }
    const request: ProductRequestDto = {
      name,
      slug: filledSlug,
      image: StringUtils.trim(image),
      useCustomImage,
      price,
      description,
      design,
      designedBy,
      tags,
      categories,
    };
    try {
      const newProduct = product
        ? await ProductApi.updateProductById(product.id, request)
        : await ProductApi.addNewProduct(request);
      onSubmit?.(newProduct);
    } catch (e) {
      NotificationUtils.showGenericError(e);
    }
  };

  return (
    <DashboardContentLayout>
      <BackButton name="PRODUCT" link={routes.PRODUCTS} />
      <FormContainer onSubmit={onSubmitForm}>
        <FormRow>
          <CustomTextField value={name} onChange={setName} label="Name" />
        </FormRow>
        <FormRow>
          <CustomTextField
            value={filledSlug}
            onChange={setSlug}
            label="SEO URL Name"
          />
        </FormRow>
        <FormRow>
          <CustomNumberInput
            value={price}
            onChange={setPrice}
            label="Price"
            inputProps={{ step: 0.01 }}
          />
        </FormRow>
        <FormRow>
          <CustomMediaPicker url={image} onUpload={onUploadImage} />
        </FormRow>
        <FormRow>
          <CustomCheckbox
            label="Override design image"
            checked={useCustomImage}
            onChange={setUseCustomImage}
          />
        </FormRow>
        <FormRow>
          <CustomTextField
            value={description}
            onChange={setDescription}
            label="Description"
            multiline
          />
        </FormRow>
        <FormRow>
          <CustomAutocomplete
            options={designOptions}
            label="Design"
            getOptionLabel={designLabel}
            value={design}
            onChange={setDesign}
          />
        </FormRow>
        <FormRow>
          <CustomAutocomplete
            options={designerOptions}
            label="Designed By"
            getOptionLabel={designerLabel}
            value={designedBy}
            onChange={setDesignedBy}
          />
        </FormRow>
        <FormRow>
          <CustomAutocomplete
            options={[]}
            label="Tags"
            value={tags}
            onChange={setTags}
            multiple
            freeSolo
          />
        </FormRow>
        <FormRow>
          <CustomAutocomplete
            options={categoryOptions}
            label="Categories"
            getOptionLabel={categoryLabel}
            value={categories}
            onChange={setCategories}
            multiple
          />
        </FormRow>
        <FormRow>
          <Button variant="contained" type="submit" size="large">
            {product ? "UPDATE" : "ADD"}
          </Button>
        </FormRow>
      </FormContainer>
    </DashboardContentLayout>
  );
}
