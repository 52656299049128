import GetItemsAction from "@paperdateco/shared-frontend/store/getItems/GetItemsAction";
import GetItemsActionTypes from "@paperdateco/shared-frontend/store/getItems/GetItemsTypes";
import { PRODUCT_GET_ITEMS_NAME } from "./ProductTypes";
import ProductApi from "@paperdateco/common/api/product/ProductApi";
import ProductDto from "@paperdateco/common/dto/product/ProductDto";
import { RootState } from "..";
import { ThunkDispatch } from "redux-thunk";

export const GetAllProducts = GetItemsAction(
  PRODUCT_GET_ITEMS_NAME,
  ProductApi.getAllAdminProducts,
  (state: RootState) => state.products
);

export type ProductStateDispatchType = ThunkDispatch<
  RootState,
  unknown,
  GetItemsActionTypes<ProductDto>
>;
