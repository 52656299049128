import { Box, Typography, styled } from "@mui/material";

import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import LibraryAnimationDto from "@paperdateco/common/dto/design/library/animation/LibraryAnimationDto";
import LibraryAnimationForm from "./LibraryAnimationForm";

const Container = styled(Box)({
  background: Colors.WHITE,
});

interface LibraryAnimationFormModalProps {
  isAdmin?: boolean;
  libraryAnimation: LibraryAnimationDto;
  onSubmit?: (libraryAnimation: LibraryAnimationDto) => void;
}

export default function LibraryAnimationFormModal({
  isAdmin,
  libraryAnimation,
  onSubmit,
}: LibraryAnimationFormModalProps) {
  return (
    <Container
      minWidth={350}
      margin={2}
      padding={3}
      maxHeight="80vh"
      overflow="auto"
    >
      <Typography variant="h6">
        {libraryAnimation
          ? `Update ${libraryAnimation.name}`
          : `Add new Animation`}
      </Typography>
      <LibraryAnimationForm
        isAdmin={isAdmin}
        libraryAnimation={libraryAnimation}
        onSubmit={onSubmit}
      />
    </Container>
  );
}
