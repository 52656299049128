import {
  CouponStateDispatchType,
  GetAllCoupons,
} from "@paperdateco/admin/store/coupons/CouponActions";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import AddNewButton from "@paperdateco/shared-frontend/components/common/form/button/AddNewButton";
import CouponApi from "@paperdateco/common/api/CouponApi";
import CouponDto from "@paperdateco/common/dto/coupon/CouponDto";
import CouponTable from "../../table/CouponTable";
import DashboardContentLayout from "@paperdateco/shared-frontend/components/layout/DashboardContentLayout";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import routes from "@paperdateco/admin/routes/routes";
import { selectCoupons } from "@paperdateco/admin/store/coupons/CouponSelector";
import { useNavigate } from "react-router-dom";

export default function CouponListPage() {
  const coupons = useSelector(selectCoupons);

  const dispatch = useDispatch<CouponStateDispatchType>();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(GetAllCoupons());
  }, [dispatch]);

  const onEditCoupon = useCallback(
    (coupon: CouponDto) => {
      navigate(routes.COUPONS_EDIT(coupon.id));
    },
    [navigate]
  );

  const onDeleteCoupon = useCallback(
    (coupon: CouponDto) => {
      CouponApi.deleteCoupon(coupon.id)
        .then(() => dispatch(GetAllCoupons(true)))
        .catch(NotificationUtils.showGenericError);
    },
    [dispatch]
  );

  return (
    <DashboardContentLayout>
      <AddNewButton
        link={routes.COUPONS_ADD}
        position="right"
        name="COUPON"
        marginBottom={2}
      />
      <CouponTable
        coupons={coupons}
        onEdit={onEditCoupon}
        onDelete={onDeleteCoupon}
      />
    </DashboardContentLayout>
  );
}
