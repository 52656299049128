import CategoryDto from "@paperdateco/common/dto/category/CategoryDto";
import Column from "../../table/Column";
import ForegroundSelect from "../../common/image/ForegroundSelect";

enum CategoryFieldNames {
  ID = "id",
  PARENT = "parent",
  NAME = "name",
  SLUG = "slug",
  IMAGE = "image",
}

const CategoryColumns: {
  [key in CategoryFieldNames]: Column<CategoryDto>;
} = {
  id: { title: "ID", field: "id" },
  parent: { title: "Parent", field: "parent" },
  name: { title: "Name", field: "name" },
  slug: { title: "Slug", field: "slug" },
  image: {
    title: "Image / Video",
    field: "image",
    render: (data) => (
      <ForegroundSelect
        src={data.image}
        alt={data.name}
        type={data.type}
        customWidth="300"
      />
    ),
  },
};

export default CategoryColumns;
