import DesignLibraryApi from "@paperdateco/common/api/design/DesignLibraryApi";
import GetItemsAction from "@paperdateco/shared-frontend/store/getItems/GetItemsAction";
import GetItemsActionTypes from "@paperdateco/shared-frontend/store/getItems/GetItemsTypes";
import { LIBRARY_IMAGE_GET_ITEMS_NAME } from "./LibraryImageTypes";
import LibraryImageDto from "@paperdateco/common/dto/design/library/image/LibraryImageDto";
import { RootState } from "..";
import { ThunkDispatch } from "redux-thunk";

export const getAllLibraryImages = GetItemsAction(
  LIBRARY_IMAGE_GET_ITEMS_NAME,
  DesignLibraryApi.getLibraryImages,
  (state: RootState) => state.libraryImages
);

export type LibraryImageStateDispatchType = ThunkDispatch<
  RootState,
  unknown,
  GetItemsActionTypes<LibraryImageDto>
>;
