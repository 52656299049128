import { Document } from "mongoose";

export enum CouponType {
  FLAT = "FLAT",
  PERCENT = "PERCENT",
}

export default interface CouponModel extends Document {
  name: string;
  type: CouponType;
  value: number;
  perUserLimit: number;
  validity?: Date;
  enabled: boolean;
}
