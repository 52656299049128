import BannerApi from "@paperdateco/common/api/bannerApi";
import BannerDto from "@paperdateco/common/dto/banner/BannerDto";
import { GET_ALL_BANNERS } from "./BannerTypes";
import GetItemsAction from "@paperdateco/shared-frontend/store/getItems/GetItemsAction";
import GetItemsActionTypes from "@paperdateco/shared-frontend/store/getItems/GetItemsTypes";
import { RootState } from "..";
import { ThunkDispatch } from "redux-thunk";

export const GetAllBanners = GetItemsAction(
  GET_ALL_BANNERS,
  BannerApi.getAllAdminBanners,
  (state: RootState) => state.banners
);

export type BannerStateDispatchType = ThunkDispatch<
  RootState,
  unknown,
  GetItemsActionTypes<BannerDto>
>;
