import BannerDto from "@paperdateco/common/dto/banner/BannerDto";
import Column from "../../table/Column";
import ForegroundSelect from "../../common/image/ForegroundSelect";
import { Typography } from "@mui/material";

enum BannerFieldNames {
  ID = "id",
  NAME = "name",
  BANNER = "banner",
  TEXT = "text",
  BUTTON = "button",
}

const BannerColumns: {
  [key in BannerFieldNames]: Column<BannerDto>;
} = {
  id: { title: "ID", field: "id" },
  name: { title: "Name", field: "name" },
  banner: {
    title: "Banner",
    field: "banner",
    render: (data) => (
      <ForegroundSelect
        src={data.bannerImage}
        alt={data.name}
        type={data.bannerType}
        customWidth="150"
      />
    ),
  },
  text: {
    title: "Text",
    field: "text",
    render: (data) => (
      <ForegroundSelect
        src={data.textImage}
        alt={data.name}
        type={data.textType}
        customWidth="150"
      />
    ),
  },
  button: {
    title: "Button",
    field: "button",
    render: (data) => (
      <>
        <Typography>Link: {data.buttonLink}</Typography>
        <Typography>Text: {data.buttonText}</Typography>
      </>
    ),
  },
};

export default BannerColumns;
