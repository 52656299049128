import Column from "@paperdateco/shared-frontend/components/table/Column";
import ProductColumns from "@paperdateco/shared-frontend/components/design/table/ProductColumns";
import ProductDto from "@paperdateco/common/dto/product/ProductDto";
import Table from "@paperdateco/shared-frontend/components/table/Table";
import useClickListener from "@paperdateco/shared-frontend/components/common/hooks/useClickListener";

const productColumns: Column<ProductDto>[] = [
  ProductColumns.name,
  ProductColumns.image,
  ProductColumns.categories,
  ProductColumns.price,
  ProductColumns.tags,
  ProductColumns.designerName,
];

const allColumns = Object.values(ProductColumns);

interface ProductTableProps {
  products: ProductDto[];
  onEditProduct?: (product: ProductDto) => string;
}

export default function ProductTable({
  products,
  onEditProduct,
}: ProductTableProps) {
  const onRowClick = useClickListener((item?: ProductDto) => {
    if (item?.id) {
      return onEditProduct?.(item);
    }
  });

  return (
    <Table
      title="Products list"
      data={products}
      columns={productColumns}
      allColumns={allColumns}
      onRowClick={onRowClick}
    />
  );
}
