import CustomRadioGroup, {
  CustomRadioOptionType,
} from "@paperdateco/shared-frontend/components/common/form/CustomRadioGroup";
import { FormEvent, useCallback, useEffect, useState } from "react";

import BackButton from "@paperdateco/shared-frontend/components/common/form/button/BackButton";
import { Button } from "@mui/material";
import CouponApi from "@paperdateco/common/api/CouponApi";
import CouponDto from "@paperdateco/common/dto/coupon/CouponDto";
import CouponRequestDto from "@paperdateco/common/dto/coupon/CouponRequestDto";
import { CouponType } from "@paperdateco/common/dto/coupon/CouponModel";
import CustomDateTimePicker from "@paperdateco/shared-frontend/components/common/form/date/CustomDateTimePicker";
import CustomNumberInput from "@paperdateco/shared-frontend/components/common/form/CustomNumberInput";
import CustomTextField from "@paperdateco/shared-frontend/components/common/form/CustomTextField";
import DashboardContentLayout from "@paperdateco/shared-frontend/components/layout/DashboardContentLayout";
import FormContainer from "@paperdateco/shared-frontend/components/common/form/FormContainer";
import FormRow from "@paperdateco/shared-frontend/components/common/form/FormRow";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import routes from "@paperdateco/admin/routes/routes";

const CouponOptionTypes: CustomRadioOptionType<CouponType>[] = [
  { name: "Flat", value: CouponType.FLAT },
  { name: "Percentage", value: CouponType.PERCENT },
];

interface CouponFormProps {
  coupon?: CouponDto;
  onSubmit?: (coupon: CouponDto) => void;
}

export default function CouponForm({ coupon, onSubmit }: CouponFormProps) {
  const [name, setName] = useState("");
  const [type, setType] = useState(CouponType.FLAT);
  const [value, setValue] = useState(0);
  const [validity, setValidity] = useState<Date>();
  const [perUserLimit, setPerUserLimit] = useState(1);

  const onChangeValidity = useCallback((value?: Date) => {
    if (!value) {
      setValidity(undefined);
      return;
    }
    setValidity(value);
  }, []);

  const onSubmitForm = async (e: FormEvent) => {
    e.preventDefault();
    const newCoupon: CouponRequestDto = {
      name,
      type,
      value,
      validity,
      perUserLimit,
    };
    try {
      const updatedCoupon = coupon
        ? await CouponApi.editCoupon(coupon.id, newCoupon)
        : await CouponApi.addNewCoupon(newCoupon);
      onSubmit?.(updatedCoupon);
    } catch (e) {
      NotificationUtils.showGenericError(e);
    }
  };

  useEffect(() => {
    setName(coupon?.name ?? "");
    setType(coupon?.type ?? CouponType.FLAT);
    setValue(coupon?.value ?? 0);
    setValidity(coupon?.validity);
    setPerUserLimit(coupon?.perUserLimit ?? 1);
  }, [coupon]);

  return (
    <DashboardContentLayout>
      <BackButton name="COUPON" link={routes.COUPONS} />
      <FormContainer onSubmit={onSubmitForm}>
        <FormRow>
          <CustomTextField value={name} onChange={setName} label="Name" />
        </FormRow>
        <FormRow>
          <CustomRadioGroup
            label="Type"
            options={CouponOptionTypes}
            value={type}
            onChange={setType}
          />
        </FormRow>
        <FormRow>
          <CustomNumberInput
            value={value}
            onChange={setValue}
            label="Value"
            inputProps={{ step: 0.01 }}
          />
        </FormRow>
        <FormRow>
          <CustomDateTimePicker
            label="Validity"
            inputFormat="dd/MM/yyyy h:mm a"
            value={validity}
            onChange={onChangeValidity}
          />
        </FormRow>
        <FormRow>
          <CustomNumberInput
            value={perUserLimit}
            onChange={setPerUserLimit}
            label="Per User Limit"
          />
        </FormRow>
        <FormRow>
          <Button variant="contained" type="submit" size="large">
            {coupon ? "UPDATE" : "ADD"}
          </Button>
        </FormRow>
      </FormContainer>
    </DashboardContentLayout>
  );
}
