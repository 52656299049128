import GetItemsAction from "@paperdateco/shared-frontend/store/getItems/GetItemsAction";
import GetItemsActionTypes from "@paperdateco/shared-frontend/store/getItems/GetItemsTypes";
import { ORDER_GET_ITEMS_NAMES } from "./OrderTypes";
import OrderApi from "@paperdateco/common/api/OrderApi";
import OrderItemDto from "@paperdateco/common/dto/order/details/OrderItemDto";
import { RootState } from "..";
import { ThunkDispatch } from "redux-thunk";

export const GetAllOrders = GetItemsAction(
  ORDER_GET_ITEMS_NAMES,
  OrderApi.getAllAdminOrders,
  (state: RootState) => state.order
);

export type OrderStateDispatchType = ThunkDispatch<
  RootState,
  unknown,
  GetItemsActionTypes<OrderItemDto>
>;
